import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { comeOrGo } from "./animation";
import { faAngleLeft, faEye, faEyeSlash, faInfo } from "@fortawesome/free-solid-svg-icons";
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import intlTelInput from 'intl-tel-input';
import { SubSink } from 'subsink';
import { ResponseResult } from 'src/app/shared/models/response';
import { ErrorMsg } from 'src/app/shared/models/error-msg.model';
import { CurrencyModel } from 'src/app/models/common-lookups/currency.model';
import { CountryModel } from 'src/app/models/common-lookups/country.model';
import { CommonLookupService } from 'src/app/services/common-lookup.service';
import { LanguageModel } from 'src/app/models/common-lookups/language.model';
import { TimeZoneModel } from 'src/app/models/common-lookups/time-zone.model';
import { CreateTenantAndAdminUserModel } from 'src/app/models/user-account/create-tenant-and-admin-user.model';
import { AuthService } from 'src/app/services/auth.service';
import { IsEmailUsedCheck } from 'src/app/settings/settings-pages/settings-users/models/is-emai-used-check';
import { UserService } from 'src/app/settings/settings-pages/settings-users/user.service';
import { IsEmailUsedResponse } from 'src/app/settings/settings-pages/settings-users/models/is-email-used-response';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip } from 'rxjs';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [comeOrGo]
})
export class SignUpComponent implements OnInit, OnDestroy {

  //Icons
  protected readonly faAngleLeft = faAngleLeft;
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faInfo = faInfo;

  //Variables
  currencyList: CurrencyModel[] = [];
  countryList: CountryModel[] = [];
  languageList: LanguageModel[] = [];
  timeZoneList: TimeZoneModel[] = [];

  isLoading: boolean = false;
  isSubmitButtonPressed = false;
  isPhoneNumberValid = false;
  isEmailUsedCheck: IsEmailUsedCheck = new IsEmailUsedCheck();
  submitButtonDisbaled = false;
  isUserEmailAlreadyTaken: boolean = false;

  currentFormStep: number = 1;
  step1Completed = false;
  step2Completed = false;

  telinput: any;
  intTelUtility: any;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  tenantAndAdminModel: CreateTenantAndAdminUserModel = new CreateTenantAndAdminUserModel();

  searchEmail = new BehaviorSubject<string>('');  

  private subs = new SubSink();

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertifyService,
    private commonLookupService: CommonLookupService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {

    this.getTimeZoneList();
    this.getCurrencyList();
    this.getCountryList();
    this.getLanguageList();

    this.telinput = document.querySelector("#phoneNumber");
    if (this.telinput) {
      this.intTelUtility = intlTelInput(this.telinput, {
        formatOnDisplay: false,
        separateDialCode: false,
        initialCountry: 'lk',
        nationalMode: false,
        utilsScript: 'assets/js/intl/utils.js'
      });
    }

    this.subs.sink = this.searchEmail.pipe(skip(1),
      debounceTime(600),
      distinctUntilChanged(),
    ).subscribe(searchEmail => {  
      this.isEmailAlreadyTaken(searchEmail);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getTimeZoneList() {
    this.subs.sink = this.commonLookupService.getTimeZoneList().subscribe({
      next: (data: ResponseResult<TimeZoneModel[]>) => {
        this.timeZoneList = data.data;
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
      },
      complete: () => {
      }
    });
  }

  getCurrencyList() {
    this.subs.sink = this.commonLookupService.getCurrencyList().subscribe({
      next: (data: ResponseResult<CurrencyModel[]>) => {
        this.currencyList = data.data;
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
      },
      complete: () => {
      }
    });
  }

  getCountryList() {
    this.subs.sink = this.commonLookupService.getCountryList().subscribe({
      next: (data: ResponseResult<CountryModel[]>) => {
        this.countryList = data.data;
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
      },
      complete: () => {
      }
    });
  }

  getLanguageList() {
    this.subs.sink = this.commonLookupService.getLanguageList().subscribe({
      next: (data: ResponseResult<LanguageModel[]>) => {
        this.languageList = data.data;
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
      },
      complete: () => {
      }
    });
  }


  validatePhoneNumber(phoneNumber: any) {
    const validTel = this.intTelUtility.isValidNumber();
    if (validTel && this.isPhoneNumberInInternationalFormat(phoneNumber)) {
      this.isPhoneNumberValid = true;
    } else {
      this.isPhoneNumberValid = false;
    }
  }

  private isPhoneNumberInInternationalFormat(phoneNumber: string): boolean {
    const phoneNumberPattern = /^\+/;
    return phoneNumberPattern.test(phoneNumber);
  }


  onCountryChange() {

    if (this.tenantAndAdminModel.countryId) {

      const selectedCountry = this.countryList.find(country => country.countryId == this.tenantAndAdminModel.countryId);

      const selectedCurrency = this.currencyList.find(currency => currency.currencyName == selectedCountry?.defaultCurrencyCode);

      if (selectedCurrency) {
        this.tenantAndAdminModel.currencyId = selectedCurrency?.currencyId;
      }

      const selectedLanguage = this.languageList.find(language => language.languageName == selectedCountry?.defaultLanguageName)

      if (selectedLanguage) {
        this.tenantAndAdminModel.languageId = selectedLanguage?.languageId;
      }

      const selectedTimezone = this.timeZoneList.find(timezone => timezone.deafultCountryCode == selectedCountry?.countryCode)

      if (selectedTimezone) {
        this.tenantAndAdminModel.timeZoneId = selectedTimezone.timezoneId;
      }

    }
  }

  isEmailAlreadyTaken(email: string){ 

    this.submitButtonDisbaled = true;

    this.isEmailUsedCheck.email = email;

    this.subs.sink = this.userService.isTenantUserEmailAlreadyTaken(this.isEmailUsedCheck).subscribe({
      next: (response: ResponseResult<IsEmailUsedResponse>) => {
        if(response.data.isEmailUsed){
          this.isUserEmailAlreadyTaken = true;          
        }else{
          this.isUserEmailAlreadyTaken = false;
          this.submitButtonDisbaled = false;
        }       
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
        this.submitButtonDisbaled = false;
      }
    });

  }

  /* START :- SignUp Form Steps */
  /* TODO: May need to refactor the logic */

  openNextStep(form: any) {
    this.isSubmitButtonPressed = true
    if (this.isValidFirstStep(form) && (this.currentFormStep == 1 || (this.isValidSecondStep(form) && (this.currentFormStep == 2) || (this.isValidThirdStep(form) && this.currentFormStep == 3)))) {
      this.currentFormStep = Math.min(this.currentFormStep + 1, 3);
      this.isSubmitButtonPressed = false;
    }
  }

  isValidFirstStep(form: any) {
    return (form.controls.companyName.valid && form.controls.companyAddress.valid && form.controls.firstName.valid &&
      form.controls.lastName.valid && form.controls.companyAddress.valid &&
      form.controls.phoneNumber.valid && this.isPhoneNumberValid);
  }

  isValidSecondStep(form: any) {
    return (form.controls.email.valid && form.controls.password.valid && (form.controls.password.value == form.controls.confirmPassword.value));
  }

  isValidThirdStep(form: any) {
    return (form.controls.countryId.valid && form.controls.currencyId.valid &&
      form.controls.languageId.valid && form.controls.timeZoneId.valid);
  }

  backToPreviousStep() {
    this.currentFormStep = Math.max(this.currentFormStep - 1, 1);
  }

  /* END :- SignUp Form Steps */


  passwordsMatch(): boolean {
    if (this.tenantAndAdminModel.password !== this.tenantAndAdminModel.confirmPassword) {
      return true;
    }
    else {
      return false;
    }
  }


  submitRegistrationForm(registrationForm: any) {
    this.isSubmitButtonPressed = true;

    if (registrationForm.valid) {
      this.isLoading = true;
      this.tenantAndAdminModel.phoneNumber = this.tenantAndAdminModel.phoneNumber.replace(/\s+/g, '');

      this.authService.signupNewTenantAndUser(this.tenantAndAdminModel)
        .subscribe({
          next: (response: ResponseResult<string>) => {
            this.alertService.showSuccess("Verification code sent to your email");            
            this.isLoading = false;            
            this.router.navigate(['/confirm'], { queryParams: { userName: this.tenantAndAdminModel.email.replace("@", "") } })
          },
          error: (error: ErrorMsg) => {
            this.alertService.showError(error);
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }
}

