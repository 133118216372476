export class InviteeRegister {
    id: string = '';
    email: string = '';
    tenantId: string = '';
    password: string = '';
    confirmPassword: string = '';
    phoneNumber: string = '';
    firstName: string = '';
    lastName: string = '';
    timezoneId: string = '';
}