import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AlertifyService } from 'src/app/shared/services/alertify.service';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss']
})
export class AccountConfirmComponent implements OnInit{
 
  isLoading: boolean = false;
  verificationCode:string="";
  isSubmitButtonPressed=false;
  userName: string = '';
  
  constructor( 
    private router:Router,   
    private authService:AuthService, 
    private alertifyService:AlertifyService,
    private route: ActivatedRoute
  ){
   
    
  }
ngOnInit(): void {  
  this.route.queryParams.subscribe((params) => {   
    if (params['userName']) {     
     this.userName = params['userName'];
    }
  });
    
}

  public confirmEmail(form:any): void {
  this.isSubmitButtonPressed=true

  if(form.valid){
    this.isLoading=true;  
    this.authService.handleSignUpConfirmation({username: this.userName, confirmationCode: this.verificationCode}).then(
      () => {
        this.isLoading= false;
        this.isSubmitButtonPressed=false
        this.router.navigate(['/login']);
        this.alertifyService.showSuccess("Account is confirmed. Please LogIn to continue!");
      })
      .catch((error) => {        
        this.isLoading=false;
        this.isSubmitButtonPressed=false;
        this.alertifyService.showError("Incorrect Confirmation Code");
    });
  }
  }

  public resendConfirmEmail(): void {
    this.authService.handleResendCode({ username: this.userName })
      .then(() => {
        this.alertifyService.showSuccess("Confirmation code resent successfully!");
      })
      .catch((error) => {
        this.alertifyService.showError("Error resending confirmation code. Please try again.");
        console.error("Error resending confirmation code:", error);
      });
  }
  
  
}
