import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthOldService} from 'src/app/services/auth.old.service';
import {UserOld} from 'src/app/models/user-old';
import {CountryOld, CurrencyOld, LanguageOld, TimeZoneOld} from 'src/app/models/comboModelsOld';
import { comeOrGo } from '../sign-up/animation';
import {faAngleLeft, faEye, faEyeSlash,faInfo} from "@fortawesome/free-solid-svg-icons";
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import intlTelInput from 'intl-tel-input';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-social-sign-up',
  templateUrl: './social-sign-up.component.html',
  styleUrls: ['./social-sign-up.component.scss'],
  animations: [comeOrGo]
})
export class SocialSignUpComponent implements OnInit, OnDestroy {

  protected readonly faAngleLeft = faAngleLeft;
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faInfo = faInfo;

  loading: boolean = false;
  isConfirm: boolean = false;
  user: UserOld = {} as UserOld;
  errorMessage: string = '';
  currentFormStep: number = 1;
  timeZoneList: TimeZoneOld[] = [];
  countryList: CountryOld[] = [];
  currencyList: CurrencyOld[] = [];
  languageList: LanguageOld[] = [];
  submitted = false;
  step1Completed=false;
  step2Completed=false;
  validationCheck=false;
  validationPhone=false;
  telinput:any
  intTelUtility:any
  isAgreedToConditions: boolean = false;
  private subs = new SubSink();

  constructor(
    private router: Router, 
    private authService: AuthOldService, 
    private httpClient: HttpClient, 
    private alertService:AlertifyService
    ) {
  }
  
  ngOnInit(): void {

    this.authService.handleSignOut();
    
    this.telinput = document.querySelector("#phoneNumber");
    if(this.telinput){
      this.intTelUtility=intlTelInput(this.telinput,{
        formatOnDisplay: false,
        separateDialCode: false,
        initialCountry: 'lk',
        nationalMode:false,
        utilsScript: 'assets/js/intl/utils.js'

      });  

    }

    this.user.firstName = this.authService.socialUserModel.firstName || '';
    this.user.lastName = this.authService.socialUserModel.lastName || '';

    this.getTimeZoneList();
    this.getCountryList();
    this.getCurrencyList();
    this.getLanguageList();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getTimeZoneList() {
    this.subs.sink = this.authService.getTimeZoneList().subscribe((data) => {
      this.timeZoneList = data
    }, (error) => {
      this.alertService.showErrorOld("Check your Internet Connection")
    });
  }

  getCountryList() {
    this.subs.sink = this.authService.getCountryList().subscribe((data) => {
      this.countryList = data;
    },(error) => {
      this.alertService.showErrorOld("Check your Internet Connection")
    });
    
  }

  getCurrencyList() {
    this.subs.sink = this.authService.getCurrencyList().subscribe((data) => {
      this.currencyList = data
    },(error) => {
      this.alertService.showErrorOld("Check your Internet Connection")
    });
  }

  getLanguageList() {
    this.subs.sink = this.authService.getLanguageList().subscribe((data) => {
      this.languageList = data
    },(error) => {
      this.alertService.showErrorOld("Check your Internet Connection")
    });
  }


  submitRegistrationForm(registrationForm:any) {

    this.validationCheck=true;
    this.submitted = true;
    
    if (registrationForm.valid) {
    
    this.loading=true;
    this.user = registrationForm.value;
    this.user.email = this.authService.socialUserModel.email || '';
    this.user.password = this.authService.socialUserModel.password || '';
    this.user.userName=this.user.email;

    this.authService.registerAdminUser(this.user)
      .subscribe(

        (data:any) => {
          
        if(data.result &&  data.result.errors && !data.result.succeeded && data.result.errors.length>0){
          this.loading=false;
          this.alertService.showErrorOld(data.result.errors[0].description);
        }

        else if(data.errors && data.errors.length>0){
          this.loading=false;
        this.alertService.showErrorOld(data.errors[0].description);
        }

        else
        {
          this.loading=false;
          this.setValues(data);
          this.alertService.showSuccess("Verification code sent to your email");
          this.validationCheck=false;
        }
          
        }
      );
    }
  }


  setValues(data: any) {
    localStorage.setItem('invitedUserName', this.user.email.replace("@",""))
    this.router.navigate(['/confirm']);

  }


  goBackToPreviousSteps() {
    this.currentFormStep--;
    if (this.currentFormStep >= 3) {
      this.currentFormStep = 1;
    }
  }

  

  onCountryChange() {
        
        if (this.user.countryId) {
            
            const selectedCountry = this.countryList.find(country => country.id.toString() == this.user.countryId);

            const selectedCurrency = this.currencyList.find(currency=> currency.text == selectedCountry?.defaultCurrency);
            if(selectedCurrency){
              this.user.currencyId = selectedCurrency?.id.toString();
            }

            const selectedLanguage = this.languageList.find(language => language.defaultLanguage == selectedCountry?.defaultLanguage)

            if(selectedLanguage){
              this.user.languageId = selectedLanguage?.id.toString();
            }

            const selectedTimezone = this.timeZoneList.find(timezone => timezone.defaultCountry == selectedCountry?.defaultCountry)

            if(selectedTimezone){
              this.user.timeZoneId = selectedTimezone.id.toString();
            }

          
        }
    }

  openNextStep(form:any) {
    this.validationCheck=true
    if(
        (this.isValidFirstStep(form)) && (this.currentFormStep==1) 
        
        || 

        (this.isValidSecondStep(form)) && (this.currentFormStep==2)   )
    {
    this.currentFormStep = Math.min(this.currentFormStep + 1, 2);
    this.validationCheck= false;
    this.user.showConfirmPassword=false;
    this.user.showPassword=false;
    }
  }
   isValidFirstStep(form:any)
  {

    this.user.phoneNumber = this.user.phoneNumber.replace(/[\s-]/g, '');
    
    return (form.controls.companyName.valid && form.controls.companyAddress.valid && form.controls.firstName.valid && 
      form.controls.lastName.valid && form.controls.companyAddress.valid &&
       form.controls.phoneNumber.valid && this.validationPhone);
  }

  isValidSecondStep(form:any)
  {
    return (form.controls.countryId.valid && form.controls.currencyId.valid && 
            form.controls.languageId.valid && form.controls.timeZoneId.valid);
  }
  openPreviousStep() {
    this.user.showConfirmPassword=false;
    this.user.showPassword=false;
    this.currentFormStep = Math.max(this.currentFormStep - 1, 1);
  }


  passwordsMatch():boolean {
    if(this.user.confirmPassword !== this.user.password){
      return true;
    }
    else{
      return false;
    }
  }

  private isPhoneNumberInInternationalFormat(phoneNumber: string): boolean {
    const phoneNumberPattern = /^\+/;     
    return phoneNumberPattern.test(phoneNumber);
  }

  validatePhoneNumber() {
    
    const validTel = this.intTelUtility.isValidNumber();
    
    if (validTel&&this.isPhoneNumberInInternationalFormat(this.user.phoneNumber)) {
      this.validationPhone = true;
    } else {
      this.validationPhone = false;
    }
  } 

}
