<div class='auth-layout'>
  <div class='login-bg-left'>
    <img alt='' src='../../../assets/auth/login-background-2.png'>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>

        <img [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt='app-logo-colorful.svg' class='app-logo mb-3'
          height='43' width='202'>
        <div class='mb-4'>
          <h3 class='welcome-title'>Forgot Password</h3>
          <p class='welcome-message'>
            Please enter the password reset code you received via email along with the new password to reset your
            account
            password.
          </p>
        </div>

        <form #forgotPasswordSubmitForm='ngForm' action='' method=''
          (ngSubmit)="onChangePassword(forgotPasswordSubmitForm)">

          <div class='form-group mb-2'>
            <label for='code'>Password Reset Code: <span class='text-danger'> *</span></label>
            <div class='input-group'>
              <span class='input-group-text'>
                <i class='fa fa-lock'></i>
              </span>
              <input #code='ngModel' [(ngModel)]='passwordResetCode' class='form-control' id='text' name='code'
                pattern='^[0-9]{6}$' placeholder='Enter the Verification Code' required type='text' />
            </div>

            @if (isSubmitButtonPressed) {
            <div>
              @if (forgotPasswordSubmitForm.controls['code'].hasError('required')) {
              <div class='text-danger' style='font-size: 14px;'>
                Password Reset Code is required.
              </div>
              }
              @if (forgotPasswordSubmitForm.controls['code'].hasError('pattern')) {
              <div class='text-danger' style='font-size: 14px;'>
                The Password Reset Code should contain six digits.
              </div>
              }
            </div>
            }
          </div>


          <div class='form-group mb-2'>
            <label for='password'>New Password: <span class='text-danger'> *</span></label>
            <div class='password-tooltip-container'> <!-- Container for tooltip -->
              <span class='password-tooltip'><img alt='info' height='14'
                  src='https://img.icons8.com/ios-filled/50/info.png' width='14' /></span>
              <span class='password-tooltip-text'> <!-- Password requirement tooltip -->
                <div class='password-tooltip-content'>
                  Password minimum length: 8
                  <br>Password requirements:
                  <br>Contains at least 1 number
                  <br>Contains at least 1 special character
                  <br>Contains at least 1 uppercase letter
                  <br>Contains at least 1 lowercase letter
                </div>
              </span>
            </div>
            <div class='input-group'>
              <span class='input-group-text'>
                <i class='fa fa-lock'></i>
              </span>
              <input #password='ngModel' [(ngModel)]='newPassword' class='form-control' id='password' name='password'
                [type]="showPassword ? 'text' : 'password'"
                pattern='^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ]{8,256}$'
                placeholder='Enter the New Password' required type='password' />

              <span (click)='showPassword = !showPassword' class='input-group-text show-hide-password'>
                <fa-icon [icon]='showPassword ? faEye : faEyeSlash'></fa-icon>
              </span>
            </div>

            @if (isSubmitButtonPressed) {
            <div>
              @if (forgotPasswordSubmitForm.controls['password'].hasError('required')) {
              <div class='text-danger' style='font-size: 14px;'>
                Password is is required.
              </div>
              }
              @if (forgotPasswordSubmitForm.controls['password'].hasError('pattern')) {
              <div class='text-danger' style='font-size: 14px;'>
                The Password should be in valid format. Click on <span
                  style="color: black; font-weight: bold; font-size: large;">i</span> icon to see the ToolTip
              </div>
              }
            </div>
            }
          </div>




          <div class='form-group mb-2'>
            <label for='confirmPassword'>Confirm New Password: <span class='text-danger'> *</span></label>
            <div class='input-group'>
              <span class='input-group-text'>
                <i class='fa fa-lock'></i>
              </span>
              <input #confirmPassword='ngModel' [(ngModel)]='confirmNewPassword'
                [type]="showConfirmPassword ? 'text' : 'password'" class='form-control' id='confirmPassword'
                name='confirmPassword' placeholder='Confirm New Password' required='' type='confirmPassword' />
              <span (click)='showConfirmPassword = !showConfirmPassword' class='input-group-text show-hide-password'>
                <fa-icon [icon]='showConfirmPassword ? faEye : faEyeSlash'></fa-icon>
              </span>
            </div>

            @if (isSubmitButtonPressed) {
            <div>
              @if (forgotPasswordSubmitForm.controls['confirmPassword'].hasError('required')) {
              <div class='text-danger' style='font-size: 14px;'>
                Confirm Password is required.
              </div>
              }
              @if (passwordsMatch() && !(forgotPasswordSubmitForm.controls['confirmPassword'].hasError('required'))) {
              <div class='text-danger' style='font-size: 14px;'>
                The Confirm Password should be same as the Password.
              </div>
              }
            </div>
            }
          </div>


          <div>
            <button [disabled]='isLoading' class='btn login-btn' type='submit'>
              @if (isLoading) {
              <span aria-hidden='true' class='spinner-border spinner-border-sm' role='status'></span>
              <span>Resetting Password</span>
              }
              @else {
              <span>Reset</span>
              }
            </button>
          </div>
        </form>

        <div>
          <p class='link'>
            Do you remember your password?
            <a [routerLink]="'/login'" class='fw-medium'>Login Now</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>