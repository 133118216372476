import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {faEye, faEyeSlash, faUser} from '@fortawesome/free-solid-svg-icons';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-password-submit',
  templateUrl: './new-password-submit.component.html',
  styleUrls: ['./new-password-submit.component.scss']
})
export class NewPasswordSubmitComponent {

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  protected readonly faUser = faUser;  

  passwordResetCode: string = '';
  newPassword: string = '';
  confirmNewPassword: string = '';
  userEmail: string = '';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isSubmitButtonPressed=false;
  isLoading=false;
  
  constructor(
    private router:Router, 
    private authService:AuthService,    
    private route: ActivatedRoute,
    private alertifyService:AlertifyService
  ){}; 

  ngOnInit(): void {    
    this.route.queryParams.subscribe((params) => {   
      if (params['email']) {     
       this.userEmail = params['email'];
      }
    });
  }

  passwordsMatch(): boolean {
    if (this.newPassword !== this.confirmNewPassword) {
      return true;
    }
    else {
      return false;
    }
  }

  onChangePassword(form:any){
    this.isSubmitButtonPressed=true;

    if(form.valid && !this.passwordsMatch()){
      this.isLoading = true;
      this.authService.forgotPasswordSubmit({username: this.userEmail, newPassword: this.newPassword, confirmationCode: this.passwordResetCode})
      .then((resp) => {
        this.isLoading=false;       
        this.alertifyService.showSuccess('Password reset successful. Please login with your new password.');
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        this.isLoading=false;       
        this.alertifyService.showError('Failed to reset password. Please try again later.');
      });

    } 

  } 

}
