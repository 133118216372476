import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { faEnvelope, faEye, faEyeSlash, faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { AlertifyService } from 'src/app/shared/services/alertify.service'

//Social login imports
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  //Areas under 'SocialLogin' comment line has not been tested

  isLoading: boolean = false;
  userEmail: string = '';
  userPassword: string = '';
  returnUrl: string | null = null;
  showPassword: boolean = false;
  isSubmitButtonPressed: boolean = false;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  protected readonly faUser = faUser;
  protected readonly faEnvelope = faEnvelope;
  protected readonly faLock = faLock;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertifyService,
    private activatedRoute: ActivatedRoute,
    private socialAuthService: SocialAuthService
  ) {

    //Social Login
    this.socialAuthService.authState.subscribe((socialUser: SocialUser) => {
      if (socialUser) {
        this.authService.IsUserExist(socialUser.email).subscribe((data) => {
          if (data) {
            this.authService.handleSignIn({
              username: socialUser.email,
              password: this.authService.generatePasswordFromSub(socialUser.idToken || '')
            })
              .then(() => {
                if (this.returnUrl) {
                  this.router.navigateByUrl(this.returnUrl)
                } else {
                  this.router.navigate(['/jobs'])
                }
                this.isLoading = false
              }).catch((err) => {
                if (err.message == 'Incorrect username or password.')
                  this.alertService.showError('Incorrect work email or password.')
                this.isLoading = false
              })
          } else {
            this.authService.socialUserModel.email = socialUser.email
            this.authService.socialUserModel.firstName = socialUser.firstName
            this.authService.socialUserModel.lastName = socialUser.lastName

            if (socialUser.idToken) {
              this.authService.socialUserModel.password = this.authService.generatePasswordFromSub(socialUser.idToken)
            }
            this.router.navigate(['/social-sign-up'])
          }
        })
      }
    })

  }

  ngOnInit() {
    //Social Login
    this.socialAuthService.signOut();

    this.authService.handleSignOut();
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
  }

  signIn(loginForm: any) {
    localStorage.clear();
    this.isSubmitButtonPressed = true;
    if (loginForm.valid) {
      this.isLoading = true;
      this.authService.handleSignIn({ username: this.userEmail.replace("@", ""), password: this.userPassword })
        .then((response) => {
          if (response && response.isSignedIn === false && response.nextStep.signInStep === "CONFIRM_SIGN_UP") {
            this.authService.handleResendCode({ username: this.userEmail.replace("@", "") }).then(() => { });
            this.router.navigate(['/confirm'], { queryParams: { userName: this.userEmail.replace("@", "") } });
            return;
          }
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate(['/jobs']);
          }
        }).catch((err) => {
          this.isLoading = false;
          this.alertService.showError('Incorrect Email or Password');
        })
    }
  }

  //Social Login
  public signInWithGoogle(): void {
    this.authService.signInWithGoogle();
  }
}
