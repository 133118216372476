<div class="auth-layout" style="background-image: url('/assets/auth/login-backtround.png')">
  <div class="card login-form-card">
    <div class="card-body">
      <img class="app-logo mb-3" [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt="app-logo-colorful.svg" height="43"
        width="202">
      <div class="registration-form">

        <div class="mb-4">
          <h3 class="welcome-title">Verify Account</h3>
          <p class="welcome-message">Let’s verify your account. Please fill those information to continue. </p>
        </div>
        <form #invitedUserCreateForm="ngForm" class="needs-validation" novalidate>
          <div class="form-group mb-2">
            <label class="col-form-label">First Name:<span class="text-danger"> *</span></label>
            <input required type="text" class="form-control" placeholder="Enter member name here."
              [(ngModel)]="invitedUser.firstName" name="firstName" #firstName="ngModel" required
              pattern="^(?!\s)[a-zA-Z0-9_.,/()?@$! ]{2,30}$" autocomplete="new-firstName" />
            <div *ngIf="validationCheck">
              <div *ngIf="invitedUserCreateForm.controls['firstName']?.hasError('required')" class="text-danger"
                style="font-size: 14px;">
                First Name is required.
              </div>
              <div *ngIf="invitedUserCreateForm.controls['firstName']?.hasError('pattern')" class="text-danger"
                style="font-size: 14px;">
                The First Name should be at least 2 characters long.
              </div>
            </div>
          </div>
          <div class="form-group mb-2">
            <label class="col-form-label">Last Name:<span class="text-danger"> *</span></label>
            <input required type="text" class="form-control" placeholder="Enter member name here."
              [(ngModel)]="invitedUser.lastName" name="lastName" #lastName="ngModel" required
              pattern="^(?!\s)[a-zA-Z0-9_.,/()?@$! ]{2,30}$" autocomplete="new-lastName" />
            <div *ngIf="validationCheck">
              <div *ngIf="invitedUserCreateForm.controls['lastName']?.hasError('required')" class="text-danger"
                style="font-size: 14px;">
                Last Name is required.
              </div>
              <div *ngIf="invitedUserCreateForm.controls['lastName']?.hasError('pattern')" class="text-danger"
                style="font-size: 14px;">
                The Last Name should be at least 2 characters long.
              </div>
            </div>
          </div>
          <div class="form-group mb-2">
            <label class="col-form-label">Email:<span class="text-danger"> *</span></label>
            <input readonly type="email" class="form-control" [(ngModel)]="invitedUser.email" email="true" name="email"
              #email="ngModel" required pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" />
            <div *ngIf="validationCheck">
              <div *ngIf="invitedUserCreateForm.controls['email']?.hasError('required')" class="text-danger"
                style="font-size: 14px;">
                Email is required.
              </div>
              <div *ngIf="invitedUserCreateForm.controls['email']?.hasError('pattern')" class="text-danger"
                style="font-size: 14px;">
                The Email should be in a valid format.
              </div>
            </div>
          </div>

          <div class="form-group mb-2">
            <label class="col-form-label">Phone Number:<span class="text-danger"> *</span></label>
            <div>
              <input required type="tel" class="form-control" id="phoneNumber" [(ngModel)]="invitedUser.phoneNumber"
                name="phoneNumber" #phoneNumber="ngModel" required style="width: 460px;"
                (ngModelChange)='validatePhoneNumber($event)' autocomplete="new-phoneNumber" />
              <div *ngIf="validationCheck">
                <div *ngIf="invitedUserCreateForm.controls['phoneNumber']?.hasError('required')" class="text-danger"
                  style="font-size: 14px;">
                  Phone Number is required.
                </div>
                <div
                  *ngIf="!isPhoneNumberValid && !(invitedUserCreateForm.controls['phoneNumber']?.hasError('required'))"
                  class="text-danger" style="font-size: 14px;">
                  The Phone Number should be in a valid format.
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mb-2">
            <label class='col-form-label' for='select-timezone-input'>Select Timezone:<span class='text-danger'>
                *</span></label>
            <div class='select-wrapper'>
              <ng-select #timezoneId='ngModel' [(ngModel)]="invitedUser.timezoneId"
                class='ng-select-dropdown form-control' bindLabel="timezoneId" bindValue="id" name="timezoneId"
                required>
                @for (timeZone of timeZoneList; track timeZone.timezoneId) {
                <ng-option [value]="timeZone.timezoneId">{{timeZone.timeZoneName }}</ng-option>
                }
              </ng-select>              
            </div>
            <div *ngIf="validationCheck">
              @if ( invitedUserCreateForm.controls['timezoneId'].hasError('required')) {
              <div class='text-danger' style='font-size: 14px;'>
                Timezone is required.
              </div>
              }
            </div>
          </div>

          <div class="form-group mb-2">
            <label for="password-input" class="form-label">Password:<span class="text-danger"> *</span></label>
            <div class="password-tooltip-container"> <!-- Container for tooltip -->
              <span class="password-tooltip"><img width="14" height="14"
                  src="https://img.icons8.com/ios-filled/50/info.png" alt="info" /></span>
              <span class="password-tooltip-text"> <!-- Password requirement tooltip -->
                <div class="password-tooltip-content">
                  Password minimum length: 8
                  <br>Password requirements:
                  <br>Contains at least 1 number
                  <br>Contains at least 1 special character
                  <br>Contains at least 1 uppercase letter
                  <br>Contains at least 1 lowercase letter
                </div>
              </span>
            </div>
            <div class="input-group">
              <input required [type]="showPassword ? 'text' : 'password'" id="password-input" required
                pattern="^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ]{8,256}$"
                class="form-control" placeholder="Enter password here." [(ngModel)]="invitedUser.password"
                name="password" #password="ngModel" autocomplete="new-password" />
              <span class="input-group-text show-hide-password" (click)="showPassword = !showPassword">
                <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
              </span>

            </div>


            <div *ngIf="validationCheck">
              <div *ngIf="invitedUserCreateForm.controls['password']?.hasError('required')" class="text-danger"
                style="font-size: 14px;">
                Password is required.
              </div>
              <div *ngIf="invitedUserCreateForm.controls['password']?.hasError('pattern')" class="text-danger"
                style="font-size: 14px;">
                The Password should be in valid format.
              </div>
            </div>
          </div>
          <div class="form-group mb-2">
            <label for="re-enter-password-input" class="form-label">Confirm Password:<span class="text-danger">
                *</span></label>
            <div class="input-group">
              <input [type]="showConfirmPassword ? 'text' : 'password'" id="re-enter-password-input"
                name="confirmPassword" required placeholder="Enter the same password again here." class="form-control"
                [(ngModel)]="invitedUser.confirmPassword" name="confirmPassword" #confirmPassword="ngModel"
                autocomplete="new-confirmPassword" />
              <span class="input-group-text show-hide-password" (click)="showConfirmPassword = !showConfirmPassword">
                <fa-icon [icon]="showConfirmPassword ? faEye : faEyeSlash"></fa-icon>
              </span>
            </div>
            <div *ngIf="validationCheck">
              <div *ngIf="invitedUserCreateForm.controls['confirmPassword']?.hasError('required')" class="text-danger"
                style="font-size: 14px;">
                Confirm Password is required.
              </div>
              <div *ngIf="passwordsMatch()&&!(invitedUserCreateForm.controls['confirmPassword']?.hasError('required'))"
                class="text-danger" style="font-size: 14px;">
                The Confirm Password should be same as the Password.
              </div>
            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-primary me-2" (click)="registerInvitedUser(invitedUserCreateForm)"
              [disabled]="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              Verify</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>