<div class='auth-layout'>
  <div class='login-bg-left'>
    <img src='../../../assets/auth/login-background-2.png' alt=''>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>

        <img class='app-logo mb-3' [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt='app-logo-colorful.svg'
          height='43' width='202'>
        <div class='mb-4'>
          <h3 class='welcome-title'>Forgot Password</h3>
          <p class='welcome-message'>
            Submit your email here to reset the password. You will get an reset confirmation to continue.
          </p>
        </div>

        <form #forgotPasswordForm='ngForm' (ngSubmit)=" requestToChangePassword(forgotPasswordForm)" method=''>
          <div class='form-group mb-2'>
            <label for='email'>Work Email: <span class='text-danger'> *</span></label>
            <div class='input-group'>
              <span class="input-group-text">
                <i class="fa fa-lock"></i>
              </span>
              <input type='email' id='email' class='form-control' placeholder='Enter Your Email Address' name='email'
                #email='ngModel' [(ngModel)]='userEmail' 
                pattern="[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?"
                required />
            </div>


            @if (isSubmitButtonPressed) {
            <div>
              @if (forgotPasswordForm.controls['email'].hasError('required')) {
              <div class='text-danger' style='font-size: 14px;'>
                Email Address is required.
              </div>
              }
              @if (forgotPasswordForm.controls['email'].hasError('pattern')) {
              <div class='text-danger' style='font-size: 14px;'>
                Invalid Email format.
              </div>
              }
            </div>
            }

          </div>
          <div>
            <button [disabled]='isLoading' class='btn login-btn' type='submit'>
              @if (isLoading) {
              <span aria-hidden='true' class='spinner-border spinner-border-sm' role='status'></span>
              <span>Submitting</span>
              }
              @else {
              <span>Submit</span>
              }
            </button>
          </div>
        </form>

        <div>
          <p class='link'>
            Do you remember your password?
            <a [routerLink]="'/login'" class='fw-medium'>Login Now</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>