import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AdminInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const anonimousReq = /SignUp/;
    if (request.url.search(anonimousReq) === -1) {


      return from(this.authService.getSession()).pipe(
        switchMap((session) => {
          let idToken = session?.tokens?.idToken?.toString() ?? '';
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
