import { inject, NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { authGuard } from './shared/guards/auth.guard'
import { NotFoundComponent } from './shared/feature/not-found/not-found.component'
import { LoginComponent } from './user-auth/login/login.component'
import { SignUpComponent } from './user-auth/sign-up/sign-up.component'
import { AccountConfirmComponent } from './user-auth/account-confirm/account-confirm.component'
import { NewPasswordSubmitComponent } from './user-auth/new-password-submit/new-password-submit.component'
import { ForgotPasswordComponent } from './user-auth/forgot-password/forgot-password.component'
import { RegisterInvitedUserComponent } from './user-auth/register-invited-user/register-invited-user.component'
import { SocialSignUpComponent } from './user-auth/social-sign-up/social-sign-up.component'
import { UserService } from './settings/settings-pages/settings-users/user.service'

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'signup', component: SignUpComponent },
  { path: 'social-sign-up', component: SocialSignUpComponent },
  { path: 'confirm', component: AccountConfirmComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-submit', component: NewPasswordSubmitComponent },
  { path: 'reg-invited-user', component: RegisterInvitedUserComponent, pathMatch: 'prefix' },
  {
    path: '',
    canActivate: [authGuard, () => inject(UserService).getLoggedInUserInfo()],
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule)
  },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
