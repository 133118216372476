export class CreateTenantAndAdminUserModel {
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  companyName: string = '';
  companyAddress: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  countryId: string | null = null;
  currencyId: string | null = null;
  languageId: string | null = null;
  timeZoneId: string | null = null;
}


