import { Component, OnDestroy, OnInit } from '@angular/core'
import { faAngleLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertifyService } from 'src/app/shared/services/alertify.service'
import intlTelInput from 'intl-tel-input';
import { SubSink } from 'subsink'
import { UserService } from 'src/app/settings/settings-pages/settings-users/user.service'
import { ErrorMsg } from 'src/app/shared/models/error-msg.model'
import { ResponseResult } from 'src/app/shared/models/response'
import { InviteeInfo } from 'src/app/settings/settings-pages/settings-users/models/invitee-infro.model'
import { InviteeRegister } from 'src/app/settings/settings-pages/settings-users/models/invitee-register.model'
import { NgForm } from '@angular/forms'
import { AuthService } from 'src/app/services/auth.service'
import { TimeZoneModel } from 'src/app/models/common-lookups/time-zone.model';
import { CommonLookupService } from 'src/app/services/common-lookup.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-register-invited-user',
  templateUrl: './register-invited-user.component.html',
  styleUrls: ['./register-invited-user.component.scss']
})
export class RegisterInvitedUserComponent implements OnInit, OnDestroy {

  protected readonly faAngleLeft = faAngleLeft
  protected readonly faEye = faEye
  protected readonly faEyeSlash = faEyeSlash

  invitedUser = new InviteeRegister();
  queryParam: any
  id: any
  validationCheck = false;
  loading: boolean = false;
  telinput: any
  email: string = '';
  tenantId: string = '';
  showConfirmPassword: boolean = false;
  showPassword: boolean = false;
  private subs = new SubSink();
  intTelUtility: any;
  isPhoneNumberValid = false;
  timeZoneList: TimeZoneModel[] = [];
  @BlockUI() blockUI!: NgBlockUI;

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertifyService,
    private route: ActivatedRoute,
    private userService: UserService,
    private commonLookupService: CommonLookupService
  ) {
  }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParams.subscribe((params) => {
      this.email = params['email'] ?? '';
      this.tenantId = params['tenantId'] ?? '';
    });

    this.authService.handleSignOut()
    localStorage.clear();

    document.addEventListener("DOMContentLoaded", () => {
      this.initializePhoneNumberInput();
    });

    this.getTimeZoneList();
    this.getInviteeInfo();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializePhoneNumberInput(): void {
    this.telinput = document.querySelector("#phoneNumber");
    if (this.telinput) {
      this.intTelUtility = intlTelInput(this.telinput, {
        formatOnDisplay: false,
        separateDialCode: false,
        initialCountry: 'lk',
        nationalMode: false,
        utilsScript: 'assets/js/intl/utils.js'
      });
    }
  }

  getInviteeInfo() {
    this.blockUI.start("Loading...");

    this.subs.sink = this.userService.getInviteeInfo(this.tenantId, this.email).subscribe({
      next: (response: ResponseResult<InviteeInfo>) => {
        let data = response.data;
        this.invitedUser.email = data.email;
        this.invitedUser.tenantId = data.tenantId;
        this.invitedUser.id = data.id;
        this.blockUI.stop();

      },
      error: (error: ErrorMsg) => {
        this.blockUI.stop();
        this.alertService.showError(error);
      }
    });
  }

  registerInvitedUser(form: NgForm): void {
    this.validationCheck = true;
    if (form.invalid) {
      return;
    }
    this.loading = true;
    this.invitedUser.phoneNumber = this.invitedUser.phoneNumber.replace(/\s+/g, '');
    this.subs.sink = this.authService.registerInvitedUser(this.invitedUser).subscribe({
      next: (response: void) => {
        this.loading = false;
        this.router.navigate(['/confirm'], { queryParams: { userName: this.invitedUser.email.replace("@", "") } })
      },
      error: (error: ErrorMsg) => {
        this.loading = false;
        this.alertService.showError(error);
      }
    });
  }

  passwordsMatch(): boolean {
    if (this.invitedUser.confirmPassword !== this.invitedUser.password) {
      return true;
    }
    else {
      return false;
    }
  }

  validatePhoneNumber(phoneNumber: any) {
    const validTel = this.intTelUtility.isValidNumber();
    if (validTel && this.isPhoneNumberInInternationalFormat(phoneNumber)) {
      this.isPhoneNumberValid = true;
    } else {
      this.isPhoneNumberValid = false;
    }
  }

  private isPhoneNumberInInternationalFormat(phoneNumber: string): boolean {
    const phoneNumberPattern = /^\+/;
    return phoneNumberPattern.test(phoneNumber);
  }

  getTimeZoneList() {
    this.subs.sink = this.commonLookupService.getTimeZoneList().subscribe({
      next: (data: ResponseResult<TimeZoneModel[]>) => {
        this.timeZoneList = data.data;
      },
      error: (error: ErrorMsg) => {
        this.alertService.showError(error);
      },
      complete: () => {
      }
    });
  }
}
