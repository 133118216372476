<div class='auth-layout'>
  <div class='login-bg-left'>
    <img src='../../../assets/auth/login-background-2.png' alt=''>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>
        <img [src]="'/assets/logo/app-logo-colorful.svg'" alt='app-logo-colorful.svg' class='app-logo mb-3'
             height='43'
             width='202'>
        <div class='mb-4'>
          <h3 class='welcome-title'>Social Sign up</h3>
          <p class='welcome-message'>Let’s create an new account. Please fill those information to continue. </p>
        </div>
        <div class='registration-form'>
          <form #registrationForm='ngForm' (ngSubmit)='submitRegistrationForm(registrationForm)'
                class='needs-validation'
                novalidate>
            <div [ngClass]="currentFormStep == 1 ? 'show' : 'hide'" class='section'>
              <h5>Company Information</h5>
              <div class='form-group mb-2'>
                <label class='col-form-label'>Company Name:<span class='text-danger'> *</span></label>
                <input #companyName='ngModel' [(ngModel)]='user.companyName' autocomplete='new-companyName'
                       class='form-control'
                       name='companyName'
                       pattern='^(?!\s)[a-zA-Z0-9_.,/()?@$!&*+-=^%#~<>|[\]{}\\:;` ]{2,50}$'
                       placeholder='Enter company name here.' required required
                       type='text' />
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['companyName']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Company Name is required.
                  </div>
                  <div *ngIf="registrationForm.controls['companyName']?.hasError('pattern')" class='text-danger'
                       style='font-size: 14px;'>
                    The Company Name is Invalid.
                  </div>
                </div>

              </div>
              <div class='form-group mb-2'>
                <label class='col-form-label'>Company Address:<span class='text-danger'> *</span></label>
                <textarea #companyAddress='ngModel' [(ngModel)]='user.companyAddress' autocomplete='new-companyAddress'
                          class='form-control' name='companyAddress'
                          pattern='^(?!\s)[a-zA-Z0-9_.,/()?@$!&*+-=^%#~<>|[\]{}\\:;` \n]{2,150}$'
                          placeholder='Enter company address here.' required
                          required></textarea>
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['companyAddress']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Company Address is required.
                  </div>
                  <div *ngIf="registrationForm.controls['companyAddress']?.hasError('pattern')" class='text-danger'
                       style='font-size: 14px;'>
                    The Company Address is invalid.
                  </div>
                </div>
              </div>
              <div class='form-group mb-2'>
                <label class='col-form-label'>First Name:<span class='text-danger'> *</span></label>
                <input #firstName='ngModel' [(ngModel)]='user.firstName' autocomplete='new-firstName'
                       class='form-control'
                       name='firstName' pattern='^(?!\s)[a-zA-Z0-9 ]{1,50}$' placeholder='Enter first name here.'
                       required
                       required type='text' />
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['firstName']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    First Name is required.
                  </div>
                  <div *ngIf="registrationForm.controls['firstName']?.hasError('pattern')" class='text-danger'
                       style='font-size: 14px;'>
                    The First Name is invalid.
                  </div>
                </div>
              </div>
              <div class='form-group mb-2'>
                <label class='col-form-label'>Last Name:<span class='text-danger'> *</span></label>
                <input #lastName='ngModel' [(ngModel)]='user.lastName' autocomplete='new-lastName' class='form-control'
                       name='lastName' pattern='^(?!\s)[a-zA-Z0-9 ]{1,50}$' placeholder='Enter last name here.' required
                       required type='text' />
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['lastName']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Last Name is required.
                  </div>
                  <div *ngIf="registrationForm.controls['lastName']?.hasError('pattern')" class='text-danger'
                       style='font-size: 14px;'>
                    The Last Name is invalid.
                  </div>
                </div>
              </div>
              <div class='form-group mb-2'>
                <label class='col-form-label'>Phone Number:<span class='text-danger'> *</span></label>
                <div class='w-100'>
                  <input #phoneNumber='ngModel' (ngModelChange)='validatePhoneNumber()' [(ngModel)]='user.phoneNumber'
                         autocomplete='new-phoneNumber'
                         class='form-control' id='phoneNumber' name='phoneNumber' required
                         required style='width: 460px;' type='tel' />
                  <div *ngIf='validationCheck'>
                    <div *ngIf="registrationForm.controls['phoneNumber']?.hasError('required')" class='text-danger'
                         style='font-size: 14px;'>
                      Phone Number is required.
                    </div>
                    <div *ngIf="!validationPhone&&!(registrationForm.controls['phoneNumber']?.hasError('required'))"
                         class='text-danger' style='font-size: 14px;'>
                      The Phone Number should be in a valid format.
                    </div>
                  </div>
                </div>
              </div>
              <div class='action-btn-wrapper form-group mb-2'>
                <button (click)='openNextStep(registrationForm)' class='btn btn-next form-control' name='firstStep'
                        type='button'>Save & Continue
                </button>
              </div>
            </div>

            <div [ngClass]=" currentFormStep == 2 ? 'show' : 'hide'" class='section'>
              <h5>Required Configurations</h5>
              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-country-input'>Select Country:<span
                  class='text-danger'> *</span></label>
                <div class='select-wrapper'>
                  <select #countryId='ngModel' (change)='onCountryChange()' [(ngModel)]='user.countryId'
                          class='form-control'
                          id='select-country-input' name='countryId' required>
                    <option hidden selected value='undefined'>-- Select Country --</option>
                    <option *ngFor='let country of countryList' [ngValue]='country.id'>
                      {{country.text}}
                    </option>
                  </select>
                  <div class='select-arrow'></div>
                </div>
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['countryId']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Country is required.
                  </div>
                </div>
              </div>
              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-currency-input'>Select Default Currency:<span
                  class='text-danger'> *</span></label>
                <div class='select-wrapper'>
                  <select #currencyId='ngModel' [(ngModel)]='user.currencyId' class='form-control' emptyText='ddd'
                          id='select-currency-input' name='currencyId' required>
                    <option hidden selected value='undefined'>-- Select Currency --</option>
                    <option *ngFor='let currency of currencyList' [ngValue]='currency.id'>
                      {{currency.text}}
                    </option>
                  </select>
                  <div class='select-arrow'></div>
                </div>
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['currencyId']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Currency is required.
                  </div>
                </div>
              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-language-input'>Select Default Language:<span
                  class='text-danger'> *</span></label>
                <div class='select-wrapper'>
                  <select #languageId='ngModel' [(ngModel)]='user.languageId' class='form-control'
                          id='select-language-input'
                          name='languageId' required>
                    <option hidden selected value='undefined'>-- Select Language --</option>
                    <option *ngFor='let lang of languageList' [ngValue]='lang.id'>
                      {{lang.text}}
                    </option>
                  </select>
                  <div class='select-arrow'></div>
                </div>
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['languageId']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Language is required.
                  </div>
                </div>
              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-timezone-input'>Select Timezone:<span
                  class='text-danger'> *</span></label>
                <div class='select-wrapper'>
                  <select #timeZoneId='ngModel' [(ngModel)]='user.timeZoneId' class='form-control'
                          id='select-timezone-input'
                          name='timeZoneId' required>
                    <option hidden selected value='undefined'>-- Select Timezone --</option>
                    <option *ngFor='let timeZone of timeZoneList' [ngValue]='timeZone.id'>
                      {{timeZone.text}}
                    </option>
                  </select>
                  <div class='select-arrow'></div>
                </div>
                <div *ngIf='validationCheck'>
                  <div *ngIf="registrationForm.controls['timeZoneId']?.hasError('required')" class='text-danger'
                       style='font-size: 14px;'>
                    Timezone is required.
                  </div>
                </div>
              </div>
              <div class='action-btn-wrapper'>
                <button (click)='openPreviousStep()' class='btn btn-go-back' type='button'>
                  <fa-icon [icon]='faAngleLeft'></fa-icon>
                </button>
                <button [disabled]='loading' class='btn login-btn' type='submit'>
        <span *ngIf='loading' aria-hidden='true' class='spinner-border spinner-border-sm'
              role='status'></span>
                  Complete
                </button>
              </div>
            </div>

            <div class='sign-up-step-message'>
              <p class='mb-0 fw-normal'>
                Account creation process is almost complete!
                <br> press continue for final configurations.
              </p>
            </div>
          </form>
        </div>

        <div class='bottom-actions'>
          <p class='link'>
            Already have an account?
            <a routerLink='/login'>Go to Login.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
