<div class='container my-5'>
  <ng-container *ngFor='let routeGroup of routeGroups'>
    <div class='card border card-body mb-2'>
      <h4 class='mb-0'>{{ routeGroup.groupName }}</h4>
      <hr class='my-3'>
      <div class='d-flex flex-wrap gap-2'>
        <ng-container *ngFor='let route of routeGroup.routes'>
          <button [routerLink]='route.path' class='btn route-btn'>
            <span *ngIf='route.authenticated' class='badge bg-success-subtle text-success'>
              <fa-icon [icon]='faLock'></fa-icon>
            </span>
            <span *ngIf='!route.authenticated' class='badge bg-secondary-subtle text-secondary'>
              <fa-icon [icon]='faLockOpen'></fa-icon>
            </span>
            <span class='route-name'>{{route.name}}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
