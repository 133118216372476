import { Component } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  protected readonly faLock = faLock;
  
  isSubmitButtonPressed = false;
  isLoading = false;
  userEmail: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertifyService,
  ) { };


  requestToChangePassword(form: any): void {
    this.isSubmitButtonPressed = true;
    if (form.valid) {
      this.isLoading = true;
      this.authService.forgotPassword({ username: this.userEmail })
        .then(() => {
          this.isLoading = false;
          this.isSubmitButtonPressed = false;
          this.alertService.showSuccess('Password reset Email sent successfully.');
          this.router.navigate(['forgot-password-submit'],{ queryParams: { 'email': this.userEmail } }); 
        })
        .catch(error => {
          console.error('Error:', error.code);
          this.alertService.showError('Failed to send reset email. Please try again later.');
        });
    }

  }
 
}







