import { Component } from "@angular/core";
import { faCheck, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

interface RouteItem {
  path: string;
  name: string
  authenticated: boolean;
}

interface RouteGroup {
  groupName: string,
  routes: RouteItem[]
}

@Component({
  selector: 'app-temp-routes',
  templateUrl: './temp-routes.component.html',
  styleUrls: ['./temp-routes.component.scss']
})
export class TempRoutesComponent {
  routeGroups: RouteGroup[] = [
    {
      'groupName': 'Authentication Routes',
      'routes': [
        {
          'path': '/login',
          'name': 'Login',
          'authenticated': false
        },
        {
          'path': '/signup',
          'name': 'Sign Up',
          'authenticated': false
        },
        {
          'path': '/confirm',
          'name': 'Email Confirmation',
          'authenticated': false
        },
        {
          'path': '/verify',
          'name': 'Email Verification',
          'authenticated': false
        },
        {
          'path': '/welcome-user',
          'name': 'Welcome User',
          'authenticated': false
        },
        {
          'path': '/reg-invited-user',
          'name': 'Register Invited User',
          'authenticated': false
        }
      ]
    },
    {
      'groupName': 'Admin Routes', 'routes': [
        {
          'path': '/admin',
          'name': 'Admin Panel',
          'authenticated': true
        },
        {
          'path': '/dashboard',
          'name': 'Admin Dashboard',
          'authenticated': true
        },
        {
          'path': '/recruitment-flow-list',
          'name': 'Jobs',
          'authenticated': true
        },
        {
          'path': '/recruitment-flow',
          'name': 'Create New Job',
          'authenticated': true
        },
        {
          'path': '/candidate-detail-new',
          'name': 'Candidate Detail',
          'authenticated': true
        },
        {
          'path': '/home',
          'name': 'Home',
          'authenticated': true
        },
        {
          'path': '/job-list',
          'name': 'Jobs(Grid)',
          'authenticated': true
        },
        {
          'path': '/message',
          'name': 'Messages',
          'authenticated': true
        },
        {
          'path': '/candidate',
          'name': 'Candidate',
          'authenticated': true
        },
        {
          'path': '/profile',
          'name': 'Profile',
          'authenticated': true
        }
      ]
    },
    {
      'groupName': 'Candidates Routes',
      'routes': [
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f',
          'name': 'Candidate Detail',
          'authenticated': true
        },
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f/profile',
          'name': 'Candidate Detail Profile Tab',
          'authenticated': true
        },
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f/communication',
          'name': 'Candidate Detail Communication Tab',
          'authenticated': true
        },
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f/review',
          'name': 'Candidate Detail Review Tab',
          'authenticated': true
        },
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f/collaboration',
          'name': 'Candidate Detail Collaboration Tab',
          'authenticated': true
        },
        {
          'path': '/candidate-detail/7968b315-3a63-4f08-9fdf-fd772f40fc1f/timeline',
          'name': 'Candidate Detail Timeline Tab',
          'authenticated': true
        },
        {
          'path': '/step/417f6595-ffe0-4341-8ee4-371877d147e6/7968b315-3a63-4f08-9fdf-fd772f40fc1f/profile',
          'name': 'Candidate Detail Profile Tab',
          'authenticated': true
        },
        {
          'path': '/step/417f6595-ffe0-4341-8ee4-371877d147e6/7968b315-3a63-4f08-9fdf-fd772f40fc1f/application',
          'name': 'Candidate Detail Application Tab',
          'authenticated': true
        }
      ]
    },
    {
      'groupName': 'Agenda Routes',
      'routes': [
        {
          'path': '/agenda',
          'name': 'Agenda',
          'authenticated': true
        }
      ]
    },
    {
      'groupName': 'Settings Routes',
      'routes': [
        {
          'path': '/setting/user',
          'name': 'User',
          'authenticated': true
        },
        {
          'path': '/setting/venues',
          'name': 'Venues',
          'authenticated': true
        },
        {
          'path': '/setting/departments',
          'name': 'Departments',
          'authenticated': true
        },
        {
          'path': '/setting/pipeline',
          'name': 'Pipelines',
          'authenticated': true
        },
        {
          'path': '/setting/template',
          'name': 'Templates',
          'authenticated': true
        },
        {
          'path': '/setting/privacy',
          'name': 'Privacy',
          'authenticated': true
        },
        {
          'path': '/setting/candidate-survey',
          'name': 'Survey',
          'authenticated': true
        },
        {
          'path': '/setting/candidate-survey-new',
          'name': 'Survey (New)',
          'authenticated': true
        },
        {
          'path': '/setting/company-profile',
          'name': 'Company Profile',
          'authenticated': true
        },
        {
          'path': '/setting/integrations',
          'name': 'Integration',
          'authenticated': true
        },
        {
          'path': '/setting/interviewstep',
          'name': 'Interview Steps',
          'authenticated': true
        },
        {
          'path': '/setting/jobroles',
          'name': 'Job Roles',
          'authenticated': true
        },
        {
          'path': '/setting/steptypes',
          'name': 'Step Types',
          'authenticated': true
        }
      ]
    },
    {
      'groupName': 'Reports Routes',
      'routes': [
        {
          'path': '/reports',
          'name': 'Reports',
          'authenticated': true
        },
        {
          'path': '/activity-report',
          'name': 'Activity Report',
          'authenticated': true
        }
      ]
    }
  ]

  protected readonly faCheck = faCheck
  protected readonly faLock = faLock
  protected readonly faLockOpen = faLockOpen
}
