import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { ConfirmModelOld, UserOld } from 'src/app/models/user-old';
import { SocialUserModel } from '../models/social-user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CountryOld, CurrencyOld, LanguageOld, TimeZoneOld } from '../models/comboModelsOld';
import { Guid } from 'guid-typescript';
import { InvitedUserOld } from '../models/invited-user';
import { SHA256, enc } from 'crypto-js';
import { decodeJWT } from 'aws-amplify/auth'

//Amplify imports
import {Amplify} from 'aws-amplify';
import { signIn, type SignInInput } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { confirmSignUp, type ConfirmSignUpInput } from 'aws-amplify/auth';
import{resetPassword, type ResetPasswordInput} from 'aws-amplify/auth';
import { confirmResetPassword, type ConfirmResetPasswordInput} from 'aws-amplify/auth';
import{getCurrentUser} from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';

//Social Sign in imports
import { SocialAuthService } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider} from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root',
})
export class AuthOldService {
  private authenticationSubject: BehaviorSubject<any>;
  activeUser:any;
  TimeZones:Array<TimeZoneOld> =[];
  Id: string = Guid.EMPTY;
  confirmModel:ConfirmModelOld={} as ConfirmModelOld;
  socialUserModel:SocialUserModel={} as SocialUserModel;
  tokenData:any;
  tenantId:any;
  userId:any;

  constructor(private http:HttpClient, private socialAuthService: SocialAuthService) {

    Amplify.configure({
      Auth: {
       Cognito: {
      ...environment.cognito,
     }
     }});
    

    this.authenticationSubject = new BehaviorSubject<boolean>(false);

  }

    //CALLS TO THE API

    adminSignUp(user:UserOld):Observable<UserOld> {
      return (this.http.post(environment.adminResourceUrl+"account/Register", user).pipe(
        map(resp => {
          const userData:any = resp;
          return userData;
        })
      ));
    }

    registerAdminUser(user:UserOld): Observable<UserOld> {
      return this.http.post<any>(environment.adminResourceUrl+"account/Accounts", user)
        .pipe(map(resp => {
          this.confirmModel.userName=resp.resultSet && resp.resultSet.length>0?resp.resultSet[1]:"";
          this.confirmModel.userId=resp.resultSet && resp.resultSet.length>1?resp.resultSet[2]:"";
          return resp;
        }));
    }

    registerInvitedUser(mUser:InvitedUserOld): Observable<any> {
      return this.http.post<any>(environment.adminResourceUrl+"account/Accounts/create", mUser)
        .pipe(map(resp => {
          return resp;
        }));
    }

    verifyAccount(mUser:InvitedUserOld): Observable<any> {
      return this.http.post<any>(environment.adminResourceUrl+"account/Accounts/verifyuser", mUser)
        .pipe(map(resp => {
          return resp;
        }));
    }

    IsUserExist(email:any): Observable<boolean> {
      return this.http.get<any>(environment.adminResourceUrl+"account/Accounts/isuserexists?email="+email)
        .pipe(map(resp => {
          return resp;
        }));
    }


    getCountryList(): Observable<CountryOld[]> {
      return  this.http.get<CountryOld[]>(environment.adminResourceUrl+"setup/Countries/list?tenantId="+this.Id)
      .pipe(catchError(this.erroHandler));
    }

    getTimeZoneList(): Observable<TimeZoneOld[]> {
      return  this.http.get<TimeZoneOld[]>(environment.adminResourceUrl+"setup/TimeZones/list?tenantId="+this.Id)
      .pipe(catchError(this.erroHandler));
    }

    getLanguageList(): Observable<LanguageOld[]> {
        return  this.http.get<LanguageOld[]>(environment.adminResourceUrl+"setup/Languages/list?tenantId="+this.Id)
        .pipe(catchError(this.erroHandler));
    }

    getCurrencyList(): Observable<CurrencyOld[]> {
      return  this.http.get<CurrencyOld[]>(environment.adminResourceUrl+"setup/Currencies/list?tenantId="+this.Id)
      .pipe(catchError(this.erroHandler));
    }

    getInviteUserCreateDetails(q:string): Observable<InvitedUserOld> {
      return  this.http.get<InvitedUserOld>(environment.adminResourceUrl+"account/Accounts/create?q="+q)
      .pipe(catchError(this.erroHandler));
    }

    erroHandler(error: HttpErrorResponse) {
      return throwError(error.message || 'server Error');
    }    

    //CALLS TO THE COGNITO POOL

    public async handleSignIn({ username, password }: SignInInput) {

        return await signIn({ username, password })
        .then(() => {

        this.authenticationSubject.next(true);

      });
      
    }

    public async handleSignUpConfirmation({username, confirmationCode}: ConfirmSignUpInput) {

        return await confirmSignUp ({username,confirmationCode});

    }

    public async handleSignOut() {
  
        return await signOut()
        .then(() => {

          localStorage.clear();   

          this.authenticationSubject.next(false);

        });
      
    }

    public async forgotPassword({ username }: ResetPasswordInput) {

      return await resetPassword({ username });

    }

    public async forgotPasswordSubmit({ username, newPassword, confirmationCode }: ConfirmResetPasswordInput) {  
        
      return await confirmResetPassword({ username, newPassword, confirmationCode });
    }



    //AUTHENTICATION METHODS

    public getUser(): Promise<any> {
    
      return getCurrentUser();
    }

    public isAuthenticated(): Promise<boolean> {
      if (this.authenticationSubject.value) {
        return Promise.resolve(true);
      } else {
        return this.getUser()
        .then((user: any) => {
          
          if (user) {
            return true;
          } else {
            return false;
          }
        }).catch(() => {
          return false;
        });
      }
    }


    public async setValues() {
      try {
        const userInfo = await this.getUser();
        this.tenantId = userInfo.attributes['custom:TenantId'];
        this.userId = userInfo.attributes['custom:UserId'];
        localStorage.setItem("userId",this.userId);
        localStorage.setItem("tenantId",this.tenantId);

      } catch (error) {
        console.error('Failed to get user info', error);
      }
    }

    getToken(): string | null {
      return localStorage.getItem('token');
    }


    public async getNewTokens(){
      try {
        const currentSession = await this.getSession();
        const token_exp = currentSession.tokens.idToken.payload.exp; 
           
        if ((token_exp* 1000)- 300 < Date.now()){
          const { tokens } = await fetchAuthSession({ forceRefresh: true });
        }
        
      } catch (e) {
      }
    }


    public async getSession(): Promise<any> {
      return await fetchAuthSession();
    }

    //SOCIAL SIGN IN METHODS

    signInWithGoogle(): void {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((x: any) => {return;});
    }
  
    signOutwithGoogle(): void {
      this.socialAuthService.signOut();
    }

    public generatePasswordFromSub = (idToken:string): string => {

      const decodedToken = decodeJWT(idToken);       
      const sub = decodedToken.payload.sub; 
      
      const hashedSub = sub ? SHA256(sub).toString(enc.Hex) : ''; 
      
      let password = hashedSub.slice(0, 12); 
  
      password += 'A'; 
      password += 'a'; 
      password += '1'; 
      password += '@'; 
  
      
      return password;
    };

}
