<div class='auth-layout'>
  <div class='login-bg-left'>
    <img src='../../../assets/auth/login-background-2.png' alt=''>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>
        <img class='app-logo mb-3' [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt='app-logo-colorful.svg'
          height='43' width='202'>
        <div class='mb-4'>
          <h3 class='welcome-title'>Account Confirmation!</h3>
          <p class='welcome-message'>Let's confirm your account. Check your email and enter the confirmation code
            below.</p>
        </div>

        <form action="" #confirmForm="ngForm" class="needs-validation" novalidate>
          <div class="form-group mb-2">
            <label for="confirm-code" class="form-label">Confirmation Code: <span class="text-danger">* </span></label>
            <input id="confirm-code" type="text" name="verificationCode" class="form-control"
              [(ngModel)]="verificationCode" pattern="^\d{6}$" placeholder="Enter confirmation code here" required>

            @if (isSubmitButtonPressed) {
              <div>
                @if (confirmForm.controls['verificationCode'].hasError('required')) {
                <div class='text-danger' style='font-size: 14px;'>
                  Confirmation Code is required.
                </div>
                }
                @if (confirmForm.controls['verificationCode'].hasError('pattern')) {
                <div class='text-danger' style='font-size: 14px;'>
                  The Confirmation Code should be a six digit number.
                </div>
                }
              </div>
              }

          </div>
          <div>
            <button type="submit" (click)="confirmEmail(confirmForm)" class="btn login-btn" [disabled]="isLoading">
              @if (isLoading) {
                <span aria-hidden='true' class='spinner-border spinner-border-sm' role='status'></span>
                <span>Confirming Account</span>
                }
                @else {
                <span>Confirm</span>
                }
            </button>
          </div>

        </form>

        <div class='create-and-forgot-pw-wrap'>
          <p class='link forgot-password-link'>
            Did not recieve the Verification Email?
            <a class='fw-bold text-dark' (click)="resendConfirmEmail()">Resend Email</a>
          </p>
          <p class='link forgot-password-link'>
            Already have an account?
            <a routerLink='/login'>Login Now.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
