<div class='auth-layout'>
  <div class='login-bg-left'>
    <img alt='' src='../../../assets/auth/login-background-2.png'>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>
        <p class="admin-panel-badge">Admin Portal</p>

        <img [src]="'/assets/logo/seeknow-logo.png'" alt='app-logo-colorful.svg' class='app-logo mb-3' height='43'
             width='202'>

        <div class='mb-4'>
          <h3 class='welcome-title'>Welcome Back!</h3>
        </div>

        <form #loginForm='ngForm' (ngSubmit)='signIn(loginForm)' class='mb-4' novalidate>
          <div class='form-group mb-3'>
            <label class='form-label' for='email'>Work Email:</label>
            <div class='input-group'>
            <span class='input-group-text icon-preview'>
                <fa-icon [icon]='faEnvelope'></fa-icon>
            </span>
              <input
                #email='ngModel'
                [(ngModel)]='userEmail'
                autocomplete='none'
                autocomplete='new-email'
                autofocus='autofocus'
                class='form-control'
                id='email'
                name='email'
                required
                type='email'
              >
            </div>

            @if (isSubmitButtonPressed) {
              <div>
                @if (loginForm.controls['email'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Email Address is required.
                  </div>
                }
              </div>
            }

          </div>


          <div class='form-group mb-3'>
            <label class='form-label' for='password'>Password:</label>
            <div class='input-group'>
            <span class='input-group-text icon-preview'>
              <fa-icon [icon]='faLock'></fa-icon>
            </span>
              <input
                #password='ngModel'
                [(ngModel)]='userPassword'
                [type]="showPassword ? 'text' : 'password'"
                autocomplete='new-password'
                class='form-control' id='password' name='password' required>
              <span (click)='showPassword = !showPassword' class='input-group-text icon-preview show-hide-password'>
              <fa-icon [icon]='showPassword ? faEye : faEyeSlash'></fa-icon>
            </span>
            </div>

            @if (isSubmitButtonPressed && loginForm.controls['password'].invalid) {
              <div class='text-danger' style='font-size: 14px;'>
                Password is required
              </div>
            }
          </div>
          <div>
            <button [disabled]='isLoading' class='btn login-btn' type='submit'>
              @if (isLoading) {
                <span aria-hidden='true' class='spinner-border spinner-border-sm' role='status'></span>
                <span>Signing In</span>
              } @else {
                <span>Log In</span>
              }
            </button>
          </div>
        </form>


        <div class='create-and-forgot-pw-wrap mb-4'>
          <p class='link forgot-password-link'>
            Forgot your password?
            <a class='fw-bold text-dark' routerLink='/forgot-password'>Reset Now.</a>
          </p>

          <p class='link create-new-account-link'>
            New Here? <br>
            Let’s
            <a class='text-dark fw-bold' routerLink='/signup'> create an account</a>
            for you.
          </p>
        </div>

        <div class='sign-in-options'>
          <asl-google-signin-button (click)="signInWithGoogle()" class='btn sign-in-option google'
                                    title='Sign in with Google account.' type='standard'>
          </asl-google-signin-button>
          <button class='btn sign-in-option apple' title='Sign in with Apple account.' type='button'>
            <img [ngSrc]="'/assets/auth/login-options/apple-logo.png'" alt='apple-logo' class='icon' height='18'
                 width='24'>
            <span>Sign in with Apple</span>
          </button>
          <button class='btn sign-in-option microsoft' title='Sign in with Microsoft account.' type='button'>
            <img [ngSrc]="'/assets/auth/login-options/microsoft-logo.png'" alt='microsoft-logo' class='icon' height='18'
                 width='24'>
            <span>Sign in with Microsoft</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
