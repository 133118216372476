import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { SharedModule } from './shared/shared.module'
import { AdminInterceptor } from './shared/interceptors/admin-interceptor.interceptor'
import { LoginComponent } from './user-auth/login/login.component'
import { ForgotPasswordComponent } from './user-auth/forgot-password/forgot-password.component'
import { AccountConfirmComponent } from './user-auth/account-confirm/account-confirm.component'
import { SignUpComponent } from './user-auth/sign-up/sign-up.component'
import { SocialSignUpComponent } from './user-auth/social-sign-up/social-sign-up.component'
import { RegisterInvitedUserComponent } from './user-auth/register-invited-user/register-invited-user.component'
import { NewPasswordSubmitComponent } from './user-auth/new-password-submit/new-password-submit.component'
import { TempRoutesComponent } from './user-auth/temp-routes/temp-routes.component'
import { environment } from 'src/environments/environment.development'

//Social login imports
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login'


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    AccountConfirmComponent,  
    RegisterInvitedUserComponent,
    TempRoutesComponent,
    NewPasswordSubmitComponent,
    SocialSignUpComponent
  ],
  imports: [
    BrowserModule,    
    BrowserAnimationsModule,    
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    SocialLoginModule,
    GoogleSigninButtonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminInterceptor,
      multi: true
    },

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GoogleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FacebookClientId)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})


export class AppModule {
}
