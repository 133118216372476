import { Component } from '@angular/core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'galleryHr-Recruitment-app';
  constructor() { }

  protected readonly faBars = faBars
  protected readonly environment = environment
}
