<div class='auth-layout'>
  <div class='login-bg-left'>
    <img src='../../../assets/auth/login-background-2.png' alt=''>
  </div>
  <div class='right-side-content'>
    <div class='card login-form-card'>
      <div class='card-body'>
        <img [ngSrc]="'/assets/logo/app-logo-colorful.svg'" alt='app-logo-colorful.svg' class='app-logo mb-3'
          height='43' width='202'>
        <div class='mb-4'>
          <h3 class='welcome-title'>Sign up</h3>
          <p class='welcome-message'>Let’s create an new account. Please fill those information to continue. </p>
        </div>
        <div class='registration-form'>
          <form #registrationForm='ngForm' (ngSubmit)='submitRegistrationForm(registrationForm)'
            class='needs-validation' novalidate>

            <div [ngClass]="currentFormStep == 1 ? 'show' : 'hide'" class='section'>
              <h5>Company Information</h5>
              <div class='form-group mb-2'>
                <label class='col-form-label'>Company Name:<span class='text-danger'> *</span></label>
                <input #companyName='ngModel' [(ngModel)]='tenantAndAdminModel.companyName'
                  autocomplete='new-companyName' class='form-control' name='companyName'
                  pattern='^(?!\s)[a-zA-Z0-9_.,/()?@$!&*+-=^%#~<>|[\]{}\\:;` ]{2,50}$'
                  placeholder='Enter company name here.' required required type='text' />

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['companyName'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Company Name is required.
                  </div>
                  }
                  @if (registrationForm.controls['companyName'].hasError('pattern')) {
                    <div class='text-danger' style='font-size: 14px;'>
                      The Company Name is Invalid.
                    </div>
                    }
                </div>
                }
              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label'>Company Address:<span class='text-danger'> *</span></label>
                <textarea #companyAddress='ngModel' [(ngModel)]='tenantAndAdminModel.companyAddress'
                  autocomplete='new-companyAddress' class='form-control' name='companyAddress'
                  pattern='^(?!\s)[a-zA-Z0-9_.,/()?@$!&*+-=^%#~<>|[\]{}\\:;` \n]{2,150}$'
                  placeholder='Enter company address here.' required></textarea>

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['companyAddress'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Company Address is required.
                  </div>
                  }
                  @if (registrationForm.controls['companyAddress'].hasError('pattern')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    The Company Address is Invalid.
                  </div>
                  }
                </div>
                }

              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label'>First Name:<span class='text-danger'> *</span></label>
                <input #firstName='ngModel' [(ngModel)]='tenantAndAdminModel.firstName' autocomplete='new-firstName'
                  class='form-control' name='firstName' pattern='^(?!\s)[a-zA-Z0-9 ]{1,50}$'
                  placeholder='Enter first name here.' required required type='text' />

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['firstName'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    First Name is required.
                  </div>
                  }
                  @if (registrationForm.controls['firstName'].hasError('pattern')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    The First Name is Invalid.
                  </div>
                  }
                </div>
                }

              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label'>Last Name:<span class='text-danger'> *</span></label>
                <input #lastName='ngModel' [(ngModel)]='tenantAndAdminModel.lastName' autocomplete='new-lastName'
                  class='form-control' name='lastName' pattern='^(?!\s)[a-zA-Z0-9 ]{1,50}$'
                  placeholder='Enter last name here.' required type='text' />

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['lastName'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Last Name is required.
                  </div>
                  }
                  @if (registrationForm.controls['lastName'].hasError('pattern')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    The Last Name is Invalid.
                  </div>
                  }
                </div>
                }

              </div>

              <div class='form-group mb-2'>
                <label class='col-form-label'>Phone Number:<span class='text-danger'> *</span></label>
                <div class='w-100'>
                  <input #phoneNumber='ngModel' (ngModelChange)='validatePhoneNumber($event)'
                    [(ngModel)]='tenantAndAdminModel.phoneNumber' autocomplete='new-phoneNumber' class='form-control'
                    id='phoneNumber' name='phoneNumber' required style='width: 460px;' type='tel' />

                  @if (isSubmitButtonPressed) {
                  <div>
                    @if (registrationForm.controls['phoneNumber'].hasError('required')) {
                    <div class='text-danger' style='font-size: 14px;'>
                      Phone Number is required.
                    </div>
                    }
                    @if (!isPhoneNumberValid && !(registrationForm.controls['phoneNumber'].hasError('required'))) {
                    <div class='text-danger' style='font-size: 14px;'>
                      The Phone Number is Invalid.
                    </div>
                    }
                  </div>
                  }
                </div>
              </div>

              <div class='action-btn-wrapper form-group mb-2'>
                <button (click)='openNextStep(registrationForm)' class='btn btn-next form-control' name='firstStep'
                  type='button'>Save & Continue
                </button>
              </div>

            </div>

            <div [ngClass]="currentFormStep == 2 ? 'show' : 'hide'" class='section'>
              <h5>User Credentials</h5>

              <div class='form-group mb-2'>
                <label class='col-form-label'>Email:<span class='text-danger'> *</span></label>
                <input #email='ngModel' [(ngModel)]='tenantAndAdminModel.email'
                  (ngModelChange)="searchEmail.next($event)" autocomplete='new-email' class='form-control' email='true'
                  name='email'
                  required type='email' />

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['email'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Email is required.
                  </div>
                  }
                </div>
                }
                @if (isUserEmailAlreadyTaken && !(registrationForm.controls['email'].hasError('required')) &&
                !(registrationForm.controls['email'].hasError('pattern'))) {
                <div class='text-danger' style='font-size: 14px;'>
                  The email is already in use. Try with a different email
                </div>
                }
              </div>

              <div class='form-group mb-2'>
                <label class='form-label' for='password-input'>Password:<span class='text-danger'> *</span></label>
                <div class='password-tooltip-container'> <!-- Container for tooltip -->
                  <span class='password-tooltip'><img alt='info' height='14'
                      src='https://img.icons8.com/ios-filled/50/info.png' width='14' /></span>
                  <span class='password-tooltip-text'> <!-- Password requirement tooltip -->
                    <div class='password-tooltip-content'>
                      Password minimum length: 8
                      <br>Password requirements:
                      <br>Contains at least 1 number
                      <br>Contains at least 1 special character
                      <br>Contains at least 1 uppercase letter
                      <br>Contains at least 1 lowercase letter
                    </div>
                  </span>
                </div>
                <div class='input-group'>
                  <input #password='ngModel' [(ngModel)]='tenantAndAdminModel.password'
                    [type]="showPassword ? 'text' : 'password'" class='form-control' id='password-input' name='password'
                    autocomplete='new-password'
                    pattern='^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?!@#%&/\\,>:;|_~`=+\- ]{8,256}$'
                    placeholder='Enter password here.' required />
                  <span (click)='showPassword = !showPassword' class='input-group-text show-hide-password'>
                    <fa-icon [icon]='showPassword ? faEye : faEyeSlash'></fa-icon>
                  </span>
                </div>

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['password'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Password is is required.
                  </div>
                  }
                  @if (registrationForm.controls['password'].hasError('pattern')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    The Password should be in valid format. Click on <span
                      style="color: black; font-weight: bold; font-size: large;">i</span> icon to see the ToolTip
                  </div>
                  }
                </div>
                }
              </div>

              <div class='form-group mb-2'>
                <label class='form-label' for='re-enter-password-input'>Confirm Password:<span class='text-danger'>
                    *</span></label>
                <div class='input-group'>
                  <input #confirmPassword='ngModel' [(ngModel)]='tenantAndAdminModel.confirmPassword'
                    [type]="showConfirmPassword ? 'text' : 'password'" class='form-control' id='re-enter-password-input'
                    name='confirmPassword' autocomplete='new-confirmPassword'
                    placeholder='Enter the same password again here.' required />

                  <span (click)='showConfirmPassword = !showConfirmPassword'
                    class='input-group-text show-hide-password'>
                    <fa-icon [icon]='showConfirmPassword ? faEye : faEyeSlash'></fa-icon>
                  </span>

                </div>

                @if (isSubmitButtonPressed) {
                <div>
                  @if (registrationForm.controls['confirmPassword'].hasError('required')) {
                  <div class='text-danger' style='font-size: 14px;'>
                    Confirm Password is required.
                  </div>
                  }
                  @if (passwordsMatch()) {
                  <div class='text-danger' style='font-size: 14px;'>
                    The Confirm Password should be same as the Password.
                  </div>
                  }
                </div>
                }
              </div>

              <div class='action-btn-wrapper'>
                <button (click)='backToPreviousStep()' class='btn btn-go-back' type='button'>
                  <fa-icon [icon]='faAngleLeft'></fa-icon>
                </button>
                <button (click)='openNextStep(registrationForm)' [disabled]="submitButtonDisbaled" class='btn btn-next'
                  type='button'>Create Account
                </button>
              </div>
            </div>


            <div [ngClass]=" currentFormStep == 3 ? 'show' : 'hide'" class='section'>
              <h5>Required Configurations</h5>

              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-country-input'>Select Country:<span class='text-danger'>
                    *</span></label>
                <div class='select-wrapper'>
                  <ng-select #countryId='ngModel' (change)='onCountryChange()'
                    [(ngModel)]="tenantAndAdminModel.countryId" bindLabel="countryId" bindValue="id"
                    id='select-country-input' class="ng-select-dropdown form-control" name="countryId" required>
                    @for (country of countryList; track country.countryId) {
                    <ng-option [value]="country.countryId">{{ country.countryName }}</ng-option>
                    }
                  </ng-select>
                  <div class='select-arrow'></div>
                </div>

                @if (isSubmitButtonPressed && registrationForm.controls['countryId'].hasError('required')) {
                <div class='text-danger' style='font-size: 14px;'>
                  Country is required.
                </div>
                }

              </div>


              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-currency-input'>Select Default Currency:<span
                    class='text-danger'> *</span></label>

                <div class='select-wrapper'>
                  <ng-select #currencyId='ngModel' [(ngModel)]='tenantAndAdminModel.currencyId' bindLabel="currencyId"
                    bindValue="id" id='select-currency-input' class="ng-select-dropdown form-control" name='currencyId'
                    required>
                    @for (currency of currencyList; track currency.currencyId) {
                    <ng-option [value]="currency.currencyId">{{ currency.currencyName }}</ng-option>
                    }
                  </ng-select>
                  <div class='select-arrow'></div>
                </div>

                @if (isSubmitButtonPressed && registrationForm.controls['currencyId'].hasError('required')) {
                <div class='text-danger' style='font-size: 14px;'>
                  Currency is required.
                </div>
                }

              </div>


              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-language-input'>Select Default Language:<span
                    class='text-danger'> *</span></label>

                <div class='select-wrapper'>
                  <ng-select #languageId='ngModel' [(ngModel)]='tenantAndAdminModel.languageId' bindLabel="languageId"
                    bindValue="id" id='select-language-input' class="ng-select-dropdown form-control" name="languageId"
                    required>
                    @for (language of languageList; track language.languageId) {
                    <ng-option [value]="language.languageId">{{ language.languageName }}</ng-option>
                    }
                  </ng-select>
                  <div class='select-arrow'></div>
                </div>

                @if (isSubmitButtonPressed && registrationForm.controls['languageId'].hasError('required')) {
                <div class='text-danger' style='font-size: 14px;'>
                  Language is required.
                </div>
                }

              </div>


              <div class='form-group mb-2'>
                <label class='col-form-label' for='select-timezone-input'>Select Timezone:<span class='text-danger'>
                    *</span></label>

                <div class='select-wrapper'>
                  <ng-select #timeZoneId='ngModel' [(ngModel)]='tenantAndAdminModel.timeZoneId' bindLabel="timeZoneId"
                    bindValue="id" id='select-timezone-input' class="ng-select-dropdown form-control" name="timeZoneId"
                    required>
                    @for (timezone of timeZoneList; track timezone.timezoneId) {
                    <ng-option [value]="timezone.timezoneId">{{ timezone.timeZoneName }}</ng-option>
                    }
                  </ng-select>
                  <div class='select-arrow'></div>
                </div>

                @if (isSubmitButtonPressed && registrationForm.controls['timeZoneId'].hasError('required')) {
                <div class='text-danger' style='font-size: 14px;'>
                  Timezone is required.
                </div>
                }

              </div>

              <div class='action-btn-wrapper'>
                <button (click)='backToPreviousStep()' class='btn btn-go-back' type='button'>
                  <fa-icon [icon]='faAngleLeft'></fa-icon>
                </button>
                <button [disabled]='isLoading' class='btn login-btn' type='submit'>
                  @if (isLoading) {
                  <span aria-hidden='true' class='spinner-border spinner-border-sm' role='status'></span>
                  <span>Creating Account</span>
                  }
                  @else {
                  <span>Complete</span>
                  }
                </button>
              </div>

            </div>

            <div class='sign-up-step-message'>
              <p class='mb-0 fw-normal'>
                Account creation process is almost complete!
                <br> press continue for final configurations.
              </p>
            </div>
          </form>
        </div>

        <div class='bottom-actions'>
          <p class='link'>
            Already have an account?
            <a routerLink='/login'>Go to Login.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>